import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import LeadCreateClientForm from '../../form/lead-create-client-form';
import DialogContent from '../common/dialog-content';
import DialogTitle from '../common/dialog-title';
import { isMobile } from 'react-device-detect';

export default function LeadClientCreate({
  open,
  onClose,
  onAfterSave,
  session,
  fromReferrer
}) {
  const [fullscreen, setFullscreen] = React.useState(isMobile);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
      fullScreen={fullscreen}
      fullWidth
    >
        <DialogTitle id="lead-create-client-dialog-title"
          title="Crie uma oportunidade"
          onClose={onClose}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
        <DialogContent dividers>
          <LeadCreateClientForm
            session={session}
            onAfterSave={onAfterSave}
            fromReferrer={fromReferrer}
          />
        </DialogContent>
      </Dialog>
  );
}
