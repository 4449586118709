import React from "react"
import { Controller } from "react-hook-form";
import FieldErrors from "../../components/form/field-errors";
import { Chip, FormControl, InputAdornment, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import WavesIcon from '@material-ui/icons/Waves';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import FormService from "../../services/form";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: 16,
  }
}));

export default function SelectTemperature({
  control,
  errorsForm,
  errorsApi,
  required
}) {
  const classes = useStyles();

  return (
    <Controller
      name="temperature"
      control={control}
      rules={{ required: required }}
      render={
        ({ field }) =>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-temperature-label">Qual o nível de interesse do cliente?{required && '*'}</InputLabel>
            <Select {...field}
              labelId="select-temperature-label"
              id="select-temperature"
              label="Qual o nível de interesse do cliente?"
              error={FormService.hasError('temperature', errorsForm, errorsApi)}
              defaultValue=''
              startAdornment={
              <InputAdornment position="start">
                <BatteryUnknownIcon size={20}/>
              </InputAdornment>
              }
              fullWidth
            >
              <MenuItem value='hot'><Chip style={{ background: 'rgb(230, 69, 69)', color: '#fff' }} label="Quente" icon={<WhatshotIcon style={{ color: '#fff' }} />} /></MenuItem>
              <MenuItem value='warm'><Chip style={{ background: 'orange', color: '#fff' }} label="Morno" icon={<WavesIcon style={{ color: '#fff' }} />} /></MenuItem>
              <MenuItem value='cold'><Chip style={{ background: 'rgb(158 155 226)', color: '#fff' }} label="Frio" icon={<AcUnitIcon style={{ color: '#fff' }} />} /></MenuItem>
            </Select>
            <FieldErrors name="temperature" errorsForm={errorsForm} errorsApi={errorsApi} />
          </FormControl>
      }
    />
  );
}
