import React from "react";

import {
  Paper,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab';

import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function Prizes({

}) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');

  const prizes = [
    {
      title: '100',
      subtile: 'Jantar com acompanhante',
      image: 'dinner.jpg',
    },
    {
      title: '150',
      subtile: 'TV 32 polegadas',
      image: 'tv_32.jpeg',
    },
    {
      title: '300',
      subtile: 'Resort com acompanhante',
      image: 'resort.jpg',
    },
    {
      title: '500',
      subtile: 'Iphone 10',
      image: 'iphone_10.jpeg',
    }
  ];

  return (
    <>
      <Timeline align="alternate">
        {prizes.map(prize => {
          return (<TimelineItem>
            <TimelineOppositeContent>
              <img src={`/images/prizes/${prize.image}`} alt="Pode ser seu!" height={matches ? 150 : 75} />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="secondary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant={matches ? 'h5' : 'h6'} component="h1">
                  {prize.title} <small style={{ fontSize: 14 }}>mil pontos</small>
                </Typography>
                <Typography variant="caption" style={{ color: 'gray' }}>{prize.subtile}</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          );
        })}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="secondary">
              <StarIcon style={{ color: 'yellow' }} />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <p style={{textAlign: 'center', color: 'gray'}}>Imagens meramente ilustrativas</p>
    </>
  );
}
