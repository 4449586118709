import { Button, CircularProgress, withStyles } from "@material-ui/core";
import React from "react";

const Spinner = withStyles()(props => (
  <CircularProgress
    className={props.classes.spinner}
    style={{marginLeft: 10}}
    size={20}
  />
))

export default function SendButton(props) {
  const {
    children,
    loading,
    ...rest
  } = props
  return (
    <Button {...rest} disabled={loading}>
      {children}
      {loading && <Spinner {...rest} />}
    </Button>
  )
}
