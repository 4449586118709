import React, { useEffect, useState } from "react"
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { useForm, Controller } from "react-hook-form";
import { getOptions } from '../../services/api';
import FieldErrors from "./field-errors";
import AppForm from "./app-form";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';
import { FormControlLabel, Grid, InputAdornment, Switch, useMediaQuery } from "@material-ui/core";
import SelectTemperature from "./select-temperature";
import { LinearProgress } from "@material-ui/core";
import SelectOption from "./select-option";
import FormService from "../../services/form";
import ControllerAutocomplete from "./controller-autocomplete";
import ApartmentIcon from '@material-ui/icons/Apartment';
import { getCurrentUser } from "../../services/auth";

export default function LeadCreateClientForm({
  onAfterSave,
  data,
  disableAuthorizationInfo,
  session,
  fromReferrer,
}) {
  const { control, setValue, handleSubmit, formState: { errors } } = useForm();
  const [errorsApi, setErrorsApi] = useState(undefined);

  const [loadingPage, setLoadingPage] = useState(false);
  const [isPartnerOrigin, setIsPartnerOrigin] = useState(false);
  const [organizationSelected, setOrganizationSelected] = useState(undefined);
  const [options, setOptions] = useState([]);

  const matches = useMediaQuery('(min-width:960px)');

  useEffect(() => {
    (async () => {
      setLoadingPage(true);
      const response = await getOptions([
        'origin',
        'budget',
        'estimated_due_date',
        'city',
        'environment'
      ]);

      const options = response.data.data;

      if (fromReferrer) {
        const user = getCurrentUser();
        const origin = options.find(option => option.id === 'origin')
        const partner = origin.values.find(value => value.key === 'partner');

        setValue('origin_id', partner.id);
        setValue('origin_organization_id', user.organization_id);
        setValue('origin_user_id', user.id);
      }

      setOptions(options);
      setLoadingPage(false);
    })();
  }, []);

  return (
    <>
      {loadingPage ? <div style={{ height: 500 }}><LinearProgress /></div> : (
        <Container component="main" maxWidth="md" style={{ maxHeight: 500 }}>
          <AppForm
            api="/workflow/lead_client"
            handleSubmit={handleSubmit}
            dataAppend={{ session: session }}
            onErrors={setErrorsApi}
            onAfterSave={onAfterSave}
            control
          >
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              defaultValue={data?.name}
              render={({ field }) => (
                <>
                  <TextField {...field}
                    label="Nome*"
                    variant="outlined"
                    margin="normal"
                    error={FormService.hasError("name", errors, errorsApi)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  <FieldErrors name="name" errors={errors} />
                </>
              )}
            />

            <Grid container spacing={matches ? 4 : 0}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={data?.phone}
                  render={({ field }) => (
                    <>
                      <TextField {...field}
                        label="Whatsapp*"
                        variant="outlined"
                        margin="normal"
                        error={FormService.hasError("phone", errors, errorsApi)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <WhatsAppIcon />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <FieldErrors name="phone" errorsForm={errors} errorsApi={errorsApi} />
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={data?.email}
                  render={({ field }) => (
                    <>
                      <TextField {...field}
                        label="E-mail"
                        variant="outlined"
                        margin="normal"
                        error={FormService.hasError("email", errors, errorsApi)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <FieldErrors name="email" errorsForm={errors} errorsApi={errorsApi} />
                    </>
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={matches ? 4 : 0}>
              <Grid item xs={12} md={12}>
                <Controller
                  name="instagram"
                  control={control}
                  defaultValue={data?.instagram}
                  render={({ field }) => (
                    <>
                      <TextField {...field}
                        label="Instagram"
                        variant="outlined"
                        margin="normal"
                        error={FormService.hasError("instagram", errors, errorsApi)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InstagramIcon />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <FieldErrors name="instagram" errorsForm={errors} errorsApi={errorsApi} />
                    </>
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={matches ? 4 : 0}>
              <Grid item xs={12} md={6}>
                <SelectOption
                  required
                  name="city_id"
                  option={options.find(option => option.id === 'city')}
                  setValue={setValue}
                  control={control}
                  errorsForm={errors}
                  errorsApi={errorsApi}
                  defaultValue={data?.city_id}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="address"
                  control={control}
                  defaultValue={data?.address}
                  render={({ field }) => (
                    <>
                      <TextField {...field}
                        label="Endereço"
                        variant="outlined"
                        margin="normal"
                        error={FormService.hasError("address", errors, errorsApi)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <FieldErrors name="address" errorsForm={errors} errorsApi={errorsApi} />
                    </>
                  )}
                />
              </Grid>
            </Grid>

            {!fromReferrer && (
              <>
                <Grid container spacing={matches ? 4 : 0}>
                  <Grid item xs={12} md={6}>
                    <SelectOption
                      required
                      name="origin_id"
                      defaultValue={data?.origin_id}
                      option={options.find(option => option.id === 'origin')}
                      setValue={setValue}
                      control={control}
                      errorsForm={errors}
                      errorsApi={errorsApi}
                      onChange={(_event, newValue) => {
                        if (newValue?.key === 'partner') {
                          setIsPartnerOrigin(true);
                        } else {
                          setIsPartnerOrigin(false);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SelectTemperature control={control} setValue={setValue} errorsForm={errors} errorsApi={errorsApi} />
                  </Grid>
                </Grid>

                {isPartnerOrigin && (
                  <Grid container spacing={matches ? 4 : 0}>
                    <Grid item xs={12} md={6}>
                      <ControllerAutocomplete
                        control={control}
                        errorsForm={errors}
                        errorsApi={errorsApi}
                        setValue={setValue}
                        onChange={(e, newValue) => {
                          setOrganizationSelected(newValue);
                        }}
                        name="origin_organization_id"
                        label="Organização"
                        apiSearchName="name"
                        api="/organization"
                        defaultValue={data?.origin_organization_id}
                        inputIcon={<ApartmentIcon />}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {organizationSelected && (
                        <ControllerAutocomplete
                          control={control}
                          errorsForm={errors}
                          errorsApi={errorsApi}
                          defaultValue={data?.origin_user_id}
                          setValue={setValue}
                          name="origin_user_id"
                          label="Usuário"
                          apiSearchName="name"
                          api={`/user?organization_id=${organizationSelected.id}`}
                          inputIcon={<PersonIcon />}
                          required
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            <SelectOption
              required
              name="budget_id"
              option={options.find(option => option.id === 'budget')}
              setValue={setValue}
              control={control}
              errorsForm={errors}
              errorsApi={errorsApi}
              defaultValue={data?.budget_id}
            />
            <SelectOption
              required
              name="estimated_due_date_id"
              option={options.find(option => option.id === 'estimated_due_date')}
              setValue={setValue}
              control={control}
              errorsForm={errors}
              errorsApi={errorsApi}
              defaultValue={data?.estimated_due_date_id}
            />
            <SelectOption
              required
              name="environments"
              multiple
              checkbox
              option={options.find(option => option.id === 'environment')}
              setValue={setValue}
              control={control}
              errorsForm={errors}
              errorsApi={errorsApi}
              defaultValue={data?.environments}
            />

            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              defaultValue={data?.description}
              render={({ field }) => (
                <>
                  <TextField {...field}
                    label="Descreva o interesse do cliente*"
                    variant="outlined"
                    margin="normal"
                    error={FormService.hasError("description", errors, errorsApi)}
                    rows={4}
                    rowsMax={6}
                    multiline
                    fullWidth
                  />
                  <FieldErrors name="description" errors={errors} />
                </>
              )}
            />

            {!disableAuthorizationInfo && <FormControlLabel required={true} control={<Switch required={true} />} label="Tenho autorização para passar os dados pessoais do cliente acima para a Criare." />}
          </AppForm>
        </Container>
      )}
    </>
  );
}