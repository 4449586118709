import { LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import apiService from '../../services/api';

export default function ApiLoading({
  children,
  api,
  onLoaded,
  disable
}) {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!disable) {
      setLoading(true);
      apiService.get(api).then(response => {
        onLoaded(response.data);
        setLoading(false);
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <LinearProgress />}
      {loaded && children}
    </>
  )
}
