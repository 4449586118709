import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import apiService from "../../services/api";
import SendButton from "./send-button";
import Swal from 'sweetalert2';
import { navigate } from "gatsby-link";
import { Alert } from "@material-ui/lab";
import ApiLoading from "../data/api-loading";
import { useQueryParam } from "use-query-params";

export default function AppForm({
  children,
  onErrors,
  handleSubmit,
  linksPath,
  viewPath,
  api,
  dataAppend = {},
  onLoadData,
  onAfterSave,
  disabled,
}) {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [data, setData] = useState();

  const [resourceId] = useQueryParam("id");

  useMemo(() => {
    if (!data) return;

    setIsEnabled(!data.deleted_at);
  }, [data]);

  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const handleClickOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleClose = () => {
    setOpenConfirmation(false);
  };

  const doInactivation = async () => {
    handleClose();

    if (isEnabled) {
      await apiService.delete(`${api}/${resourceId}`);
    } else {
      await apiService.put(`${api}/${resourceId}/restore`);
    }

    Swal.fire({
      title: isEnabled ? 'Inativado com sucesso' : 'Ativado com sucesso',
      icon: 'success',
      confirmButtonColor: '#000',
    });

    setIsEnabled(!isEnabled);
  };

  const onSubmit = async data => {
    onErrors(undefined);
    setHasError(false);
    setLoading(true);

    const dataToSend = { ...dataAppend, ...data };

    try {
      const response = !resourceId ? (await apiService.post(api, dataToSend)) : (await apiService.put(`${api}/${resourceId}`, dataToSend));

      if (onAfterSave) {
        onAfterSave(response.data);
      }

      setLoading(false);

      Swal.fire({
        title: !resourceId ? 'Sucesso ao criar' : 'Sucesso ao alterar os dados',
        icon: 'success',
        confirmButtonColor: '#000',
        willClose: () => {
          if (resourceId || (!linksPath && !viewPath) || onAfterSave) return;

          if (linksPath) {
            navigate(`${linksPath}/update?id=${response.data.id}`);
          } else if (viewPath) {
            navigate(`${viewPath}?id=${response.data.id}`);
          }
        }
      });
    } catch (error) {
      onErrors(error?.response?.data);
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <ApiLoading
      api={`${api}/${resourceId}`}
      disable={!resourceId}
      onLoaded={(data) => {
        onLoadData && onLoadData(data);
        setData(data);
      }}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <Card>
          <CardContent>
            {children}
            {hasError && <Alert style={{ marginTop: 20 }} severity="error">Erro ao enviar o formulário.</Alert>}
          </CardContent>

          {!disabled && (
            <>
              <Divider />
              <CardActions>
                {resourceId && (
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ borderColor: '#FF8882', color: '#FF8882' }}
                    onClick={handleClickOpenConfirmation}
                  >
                    {isEnabled ? 'Inativar' : 'Ativar'}
                  </Button>
                )}

                {isEnabled && (
                  <SendButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={loading}
                    fullWidth
                  >
                    Salvar
                  </SendButton>
                )}
              </CardActions>
            </>
          )}
        </Card>

        <Dialog
          open={openConfirmation}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Inativação do registro</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Confirma {isEnabled ? 'inativar' : 'ativar'} esse registro?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Não
          </Button>
            <Button onClick={doInactivation} color="primary">
              Sim
          </Button>
          </DialogActions>
        </Dialog>
      </form>
    </ApiLoading>
  );
}
