import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form";
import { CircularProgress } from "@material-ui/core";
import FieldErrors from "../form/field-errors";
import { getDefaultValues } from "../../services/api";
import AppAutocomplete from "./app-autocomplete";

export default function ControllerAutocomplete({
  control,
  setValue,
  data,
  name,
  apiSearchName,
  label,
  errorsForm,
  errorsApi,
  api,
  required,
  multiple,
  fieldId = 'id',
  inputIcon,
  onChange,
  variant="outlined",
  disabled,
}) {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(undefined);
  const [defaultValue, setDefaultValue] = useState();

  useEffect(() => {
    (async () => {
      if (!data) {
        return setLoading(false);
      }

      const response = await getDefaultValues(api, data, name, multiple, fieldId);
      const responseData = response?.data?.data;
      const option = (multiple) ? responseData : responseData?.find(option => option[fieldId] === data[name]);

      if (option) {
        setDefaultValue(option);

        if (multiple) {
          setValue(name, option.map(o => o[fieldId]));
        } else {
          setValue(name, option[fieldId]);
        }
      }
      
      setOptions(response?.data?.data);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading ? <CircularProgress /> : (
        <Controller
          name={`obj_${name}`}
          control={control}
          rules={{ required: required }}
          defaultValue={defaultValue}
          render={
            ({ field }) =>
              <>
                <AppAutocomplete
                  setValue={setValue}
                  data={data}
                  name={name}
                  apiSearchName={apiSearchName}
                  label={label}
                  errorsForm={errorsForm}
                  errorsApi={errorsApi}
                  api={api}
                  required={required}
                  multiple={multiple}
                  fieldId={fieldId}
                  inputIcon={inputIcon}
                  onChange={onChange}
                  TextFieldProps={{ variant: variant }}
                  field={field}
                  optionsDefault={options}
                  disabled={disabled}
                />
                <FieldErrors name={name} errorsForm={errorsForm} errorsApi={errorsApi} />
              </>
          }
        />
      )}
    </>
  );
}
